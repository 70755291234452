import {
    get,
    post,
    del
} from '../interface'

// 获取字典类型
async function getDictType(params) {
    return await get(`api-user/dicts/type/list`, params)
}

// 添加，修改 字典类型
async function editDictType(params) {
    return await post(`/api-user/dicts/type`, params, {
        prompt: true
    })
}

// 删除 字典类型
async function deleteDictType(params) {
    return await del(`/api-user/dicts/type`, params, {
        prompt: true
    })
}

// 获取字典数据
async function getDictData(params) {
    return await get(`api-user/dict/datas/list`, params)
}

// 添加，修改 字典数据
async function editDictData(params) {
    return await post(`/api-user/dict/datas`, params, {
        prompt: true
    })
}

// 删除 字典数据
async function deleteDictData(params) {
    return await del(`/api-user/dict/datas`, params, {
        prompt: true
    })
}

// 获取系统参数
async function getSystemParams(params) {
    return await get(`/api-user/sys/configs/list`, params)
}

// 添加，修改 系统参数
async function editSystemParams(params) {
    return await post(`/api-user/sys/configs`, params, {
        prompt: true
    })
}

// 删除 系统参数
async function deleteSystemParams(params) {
    return await del(`/api-user/sys/configs`, params, {
        prompt: true
    })
}

// 获取菜单管理列表
async function getMenus(params) {
    return await get('/api-user/menus/alls', params)
}

// 添加，修改菜单
async function editMenu(type, params) {
    return await post(`/api-user/menus/saveOrUpdate?tenantId=${type}`, params, {
        prompt: true
    })
}

// 删除菜单
async function deleteMenu(type, id) {
    return await del(`/api-user/menus/${id}?tenantId=${type}`)
}

// css: ""
// hidden: "false"
// id: ""
// name: "菜单管理"
// parentId: "1341926450730643478"
// path: "/home/menus"
// pathMethod: ""
// sort: "4"
// tenantId: "internal"
// type: "1"
// url: ""



export default {
    getDictType,
    editDictType,
    deleteDictType,
    getDictData,
    editDictData,
    deleteDictData,
    getSystemParams,
    editSystemParams,
    deleteSystemParams,
    getMenus,
    editMenu,
    deleteMenu
}