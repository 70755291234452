import axios from 'axios'
import router from '../router/index.js'
import { config } from '@/config/config'
import { Message } from 'element-ui';

//sss
// 默认路径 接口所用
let baseURL = config.VUE_APP_URL

// 引入Base64
let Base64 = require('js-base64').Base64

let http = axios.create()
http.defaults.baseURL = baseURL
http.defaults.timeout = 30000 // 响应超时时间
http.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'
http.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

const cancelToken = axios.CancelToken;
// const source = CancelToken.source();
const pending = []
const removePending = (key) => {
  const i = pending.findIndex(item => item.u === key)
  i !== -1 && pending.splice(i, 1)
}
// 请求拦截
http.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('access_token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      let an = Base64.encode('internal:internal')
      let bn = 'Basic ' + an
      config.headers.Authorization = bn
    }
    config.cancelToken = new cancelToken((c) => {
      pending.push({ u: config.url + '&' + config.method, f: c });
    });
    return config
  },
  (error) => {
    return Promise.reject(error)
  })

// 响应拦截
http.interceptors.response.use(
  (response) => {
    // if (response.respCode) {
    //   this.$message.error(response.respMsg)
    //   return
    // }
    const { url, method } = response.config
    removePending(url + '&' + method)
    return response
  },
  (error) => {
    const { url, method } = error.response.config
    removePending(url + '&' + method)
    if (error.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
      console.log("错误回调", error);         // reject这个错误信息
      Message.error('请求超时！！！')
    }
    // 错误响应处理
    if (error.response) {
      // 401 无权限处理
      if (error.response.status === 401) {
        localStorage.clear()
        // pending.forEach(c => c())
        router.replace({
          name: 'login'
        })
      }
    }
    // console.log(error)
    return Promise.reject(error.response.data)
  })




export default http