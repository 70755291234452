import { post, put } from '../interface'

async function addPersonScenario (params) {
    return post('/api-scenario/scenario/chapter/person', params)
}

async function updatePersonScenario (params) {
    return put('/api-scenario/scenario/chapter/person', params)
}

export default {
    addPersonScenario,
    updatePersonScenario
}