import {
    post,
    get,
    // put,
    del
} from '../interface'


// 营销管理-奖励管理-每日签到添加及修改
async function addRewardSign(params) {
    return await post(`/api-file/manage/marketing/sign`, params, {
        prompt: true
    })
}
// 每日签到列表
async function getRewardSign(params) {
    return await get(`/api-file/manage/marketing/sign/page`, params)
}
// 营销管理-奖励管理-每日签到删除
async function deleteRewardSign(id) {
    return await del(`/api-file/manage/marketing/sign`, id, {
        prompt: true
    })
}


// 营销管理-奖励管理-开局奖励添加及修改
async function addStartreward(params) {
    return await post(`/api-file/manage/marketing/startreward`, params, {
        prompt: true
    })
}
// 开局奖励列表
async function getStartreward(params) {
    return await get(`/api-file/manage/marketing/startreward/page`, params)
}

// 营销管理-奖励管理-开局奖励删除
async function deleteStartreward(id) {
    return await del(`/api-file/manage/marketing/startreward`, id, {
        prompt: true
    })
}



export default {
    addRewardSign,
    getRewardSign,
    deleteRewardSign,
    addStartreward,
    getStartreward,
    deleteStartreward
}