const config = {
  // 嘉浩
  // VUE_APP_URL: 'http://192.168.31.118:9900',

  // 本地
  // VUE_APP_URL: 'http://127.0.0.1:9900', 

  //天辉
  // VUE_APP_URL: 'http://192.168.31.66:9900', 

  // VUE_APP_URL: 'http://192.168.31.67:9900', //天辉测试

  // 测试环境
  // VUE_APP_URL: 'http://192.168.31.123:9900',

  // 生产环境
  // VUE_APP_URL: 'https://api.melondetective.com',

  // // 测试环境
  VUE_APP_URL: 'https://testapi.melondetective.com',
}
const { search } = window.location || ''
const env = process.env.NODE_ENV
if (search && env === 'development') {
  config.VUE_APP_URL = `http://192.168.31.${search.substr(1)}:9900`
} else if (process.env.VUE_APP_URL) {
  Object.assign(config, process.env)
}
export {
  config
}
