<template>
  <!-- 上传图片弹窗 -->
  <div class="uploadImage">
    <el-button
      style="
        border: 1px solid rgba(197, 192, 224, 1);
        background: rgba(197, 192, 224, 1);
        color: #333;
      "
      type="warning"
      size="mini"
      @click="addPicture"
      >上传图片</el-button
    >

    <el-dialog
      :visible.sync="dialog"
      align="center"
      title="上传图片"
      :append-to-body="true"
      width="60%"
      :close-on-click-modal="false"
    >
      <div class="row2">
        <div
          v-for="(item, i) in scList"
          :key="i"
          style="padding: 0 10px; width: 45%; margin-bottom: 50px"
        >
          <div style="display: flex; justify-content: center">
            <el-upload
              class="avatar-uploader"
              :action="$config.VUE_APP_URL + $filesUrl[0]"
              :show-file-list="false"
              :on-success="uploadSuccess.bind(this, i)"
              :headers="{
                Authorization: `bearer ${accessToken}`,
              }"
              :data="{
                fileType: 1,
              }"
              :before-upload="beforeUpload"
            >
              <img
                v-if="item.picturePath"
                :src="item.picturePath"
                class="avatar"
              />
              <div v-else>
                <i class="el-icon-plus avatar-uploader-icon"></i>
                <div>图片要求：</div>
                <div>PNG格式，2M以内大小。</div>
              </div>
            </el-upload>
          </div>

          <div
            style="
              display: flex;
              justify-content: center;
              margin-top: 20px;
              align-items: center;
            "
          >
            <el-tag size="small" style="width: 96px" effect="dark">名称</el-tag>
            <el-input
              v-model="item.content"
              placeholder="请输入素材名"
              size="mini"
              style="width: 200px; margin-left: 12px"
            ></el-input>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              margin-top: 8px;
              align-items: center;
            "
          >
            <el-tag size="small" style="width: 96px" effect="dark"
              >关联剧本</el-tag
            >
            <el-select
              v-model="item.scenarioId"
              style="width: 200px; margin-left: 12px"
              size="mini"
              placeholder="剧本"
            >
              <el-option label="全部剧本" :value="'0'"></el-option>
              <el-option
                v-for="(option, oi) in scenarioList"
                :key="oi"
                :label="option.scenarioName"
                :value="option.id"
              ></el-option>
            </el-select>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 8px">
            <el-select
              v-model="item.pictureTypeId"
              style="width: 100px"
              size="mini"
              placeholder="类别"
            >
              <el-option
                v-for="(option, oi) in list4"
                :key="oi"
                :label="option.dictLabel"
                :value="option.dictValue"
              ></el-option>
            </el-select>

            <el-select
              style="width: 100px; margin-left: 10px"
              size="mini"
              placeholder="性别"
              v-model="item.pictureSexId"
            >
              <el-option label="全部" :value="'0'"></el-option>
              <el-option
                v-for="(option, oi) in list3"
                :key="oi"
                :label="option.dictLabel"
                :value="option.dictValue"
              ></el-option>
            </el-select>

            <el-select
              style="width: 100px; margin-left: 10px"
              size="mini"
              placeholder="年龄"
              v-model="item.pictureAgeId"
            >
              <el-option label="全部" :value="'0'"></el-option>
              <el-option
                v-for="(option, oi) in list2"
                :key="oi"
                :label="option.dictLabel"
                :value="option.dictValue"
              ></el-option>
            </el-select>
            <el-select
              style="width: 100px; margin-left: 10px"
              size="mini"
              placeholder="时空"
              v-model="item.pictureStyleId"
            >
              <el-option
                v-for="(option, oi) in list1"
                :key="oi"
                :label="option.dictLabel"
                :value="option.dictValue"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="row3">
        <el-button
          v-loading="loading"
          :disabled="loading"
          type="primary"
          @click="sureUpload"
          >确认上传</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      loadingTimer: null,
      accessToken: localStorage.getItem("access_token"),
      scList: [],
    };
  },
  created() {
    this.createdList();
  },
  computed: {
    ...mapState(["scenarioList"]),
    list1() {
      return this.$store.state.dict.list1;
    },
    list2() {
      return this.$store.state.dict.list2;
    },
    list3() {
      return this.$store.state.dict.list3;
    },
    list4() {
      return this.$store.state.dict.list4;
    },
  },
  watch: {
    loading(val) {
      if (val) {
        this.loadingTimer = setTimeout(() => {
          this.loading = false;
        }, 10000);
      } else {
        clearTimeout(this.loadingTimer);
      }
    },
  },
  methods: {
    addPicture() {
      this.dialog = true;
      this.scList = [];
      this.createdList();
    },
    createdList() {
      // 创建图片上传对象
      for (let i = 0; i < 10; i++) {
        this.scList.push({
          picturePath: "", // 图片路径
          content: "", // 素材名
          pictureTypeId: "1", // 类别ID
          pictureSexId: "1", // 性别ID
          pictureAgeId: "1", // 年龄ID
          pictureStyleId: "1", // 时空ID
        });
      }
    },
    beforeUpload(file) {
      const isPNG = file.type === "image/png";
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!(isPNG || isJPG || isGIF)) {
        this.$message.error("上传图片只能是 PNG或JPG, GIF格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return (isPNG || isJPG || isGIF) && isLt2M;
    },
    uploadSuccess(i, res) {
      this.scList[i].picturePath = res.url;
    },
    async sureUpload() {
      let beforeList = [];
      let next = true;
      this.scList.forEach((ele, ei) => {
        if (ele.picturePath) {
          if (!ele.content) {
            this.$message.error(`请输入第${ei + 1}位素材名`);
            next = false;
          }
          if (!ele.scenarioId) {
            this.$message.error(`请选择第${ei + 1}位剧本类型`);
            next = false;
          }
          beforeList.push(ele);
        }
      });
      if (!next) return;
      this.loading = true;
      const res = await this.getRequest.surePushPicture(beforeList);
      if (res.respCode === 0) {
        this.$message({
          message: "素材上传成功！",
          type: "success",
        });
        this.dialog = false;
        this.scList = [];
        this.createdList();
      } else {
        this.$message({
          message: res.respMsg,
          type: "error",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 198px;
  height: 148px;
}
.avatar-uploader .el-upload:hover {
  border-color: #7f76bd;
}
.avatar {
  width: 198px;
  height: 148px;
  display: block;
}
</style>

<style lang='scss' scoped>
.row1 {
  display: flex;
  justify-content: center;
  .title {
    background: rgba(236, 234, 250, 1);
    border-radius: 4px;
    width: 100px;
    height: 24px;
    text-align: center;
    color: #333;
    line-height: 24px;
    font-weight: bold;
  }
}
.row2 {
  display: flex;
  flex-wrap: wrap;
  height: 55vh;
  overflow-y: scroll;
  justify-content: space-between;
}
.row3 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>