import {
  post,
  get,
  put,
  del
} from '../interface'

// 全部商品页面

// 获取商品列表
async function getShopList(params) {
  return await post('/api-user/product/page', params)
}

// 添加商品
async function addShop(params) {
  return await post('/api-user/product/save', params, {
    prompt: true
  })
}

// 更新商品
async function editShop(params) {
  return await put('/api-user/product/update', params, {
    prompt: true
  })
}

// 获取所有展区
async function getZoneList() {
  return await get('/api-user/product/exhibition/message')
}

// 获取所有展区详情
async function getZoneDetailList() {
  return await get('/api-user/product/exhibition/query/all')
}

// 获取所有剧本
async function getScriptList(params) {
  return await get('/api-scenario/scenario/audit/page', params)
}

// 添加修改删除展区
async function addShow(params) {
  return await put('/api-user/product/exhibition/change/relevance', params, {
    prompt: true
  })
}

// 发售，停售商品
async function goodsStatusOperation(params) {
  return await put('/api-user/product/sell', params, {
    prompt: true
  })
}

// 根据id获取商品详情信息
async function getGoodsDetail(id) {
  // return await get({ url: `/api-user/product/sell/${id}`, config: {  }})
  return await get(`/api-user/product/detail/${id}`)
}

// 根据id删除商品
async function deleteGoods(params) {
  // return await get({ url: `/api-user/product/sell/${id}`, config: {  }})
  return await del(`/api-user/product/sell`, params, {
    prompt: true
  })
}

// 全部优惠券页面

// 获取优惠券列表
async function getDiscountList(params) {
  return await get('/api-user/product/discounts/page', params)
}

// 添加优惠券
async function addCoupon(params) {
  return await post('/api-user/product/discounts', params, {
    prompt: true
  })
}

// 编辑优惠券
async function editCoupon(params) {
  return await put('/api-user/product/discounts', params, {
    prompt: true
  })
}

// 停止或发行优惠券
async function couponStatusOperation(params) {
  return await put('/api-user/product/discounts/issue', params, {
    prompt: true
  })
}

// 根据id删除优惠券
async function deleteCoupon(params) {
  // return await get({ url: `/api-user/product/sell/${id}`, config: {  }})
  return await del(`/api-user/product/discounts`, params, {
    prompt: true
  })
}

// 获取订单列表
async function getOrderList(params) {
  return await get(`/api-user/product/order/page`, params)
}

// 获取二维码列表
async function get2dCodeList(params) {
  return await get('/api-user/qr/code/welfare/backstage/page', params)
}

// 创建二维码
async function add2dCode(params) {
  return await post('/api-user/qr/code/welfare/backstage', params)
}

// 修改二维码
async function edit2dCode(params) {
  return await put('/api-user/qr/code/welfare/backstage', params)
}

// 删除二维码
async function delete2dCode(id) {
  return await del('/api-user/qr/code/welfare/backstage', id)
}

// 切换商城显隐
async function changeShopDisplay(display) {
  let params = { id: "1339450531369836579", configName: "商城开关", configKey: "product_display", configValue: display, remark: "" }
  return await post('/api-user/sys/configs', params)
}

// 获取当前商城显隐状态
async function getShopDisplay() {
  return await get('/api-user/sys/configs/list')
}

// 获取当前剧本线索和小剧场树信息
async function getScenarioPaidContent(id) {
  return await get(`/api-scenario/manage/scenario/${id}`)
}

// 后台添加商品信息
async function addManageProduct(params) {
  return await post('/api-user/manage/product', params, {prompt: true})
}
// 后台获取商品信息列表
async function getManageProductList(params) {
  return await post('/api-user/manage/product/page', params, {prompt: false})
}

// 获取当前剧本线索信息
async function getScenarioPaidClue(id) {
  return await get(`/api-scenario/manage/scenario/clue/${id}`)
}

// 获取当前剧本线索信息
async function getPayTip(id) {
  return await get(`/api-scenario/manage/scenario/payTip/${id}`)
}



export default {
  getShopList,
  addShop,
  getZoneList,
  getScriptList,
  addShow,
  getDiscountList,
  goodsStatusOperation,
  getZoneDetailList,
  getGoodsDetail,
  editShop,
  deleteGoods,
  addCoupon,
  couponStatusOperation,
  editCoupon,
  deleteCoupon,
  getOrderList,
  get2dCodeList,
  add2dCode,
  edit2dCode,
  delete2dCode,
  changeShopDisplay,
  getShopDisplay,
  getScenarioPaidContent,
  addManageProduct,
  getManageProductList,
  getScenarioPaidClue,
  getPayTip
}