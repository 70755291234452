<!--  -->
<template>
  <div class="">
    <audio
      id="audio"
      ref="audio"
      @pause="onPauseHandler"
      @ended="endedHandler"
      @play="onPlayHandler"
      @timeupdate="onTimeupdateHandler"
      @loadedmetadata="onLoadedmetadataHandler"
      @canplay="canplayHandler"
      preload="auto"
      :src="audioSrc"
      controls="controls"
      style="display: none"
    ></audio>
  </div>
</template>

<script>
// mapMutations
let audio = null;
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState(["audioStatus", "audioSrc"]),
  },
  mounted() {
    audio = document.getElementById("audio");
    this.$bus.$on("changeCurTime", (v) => (audio.currentTime = v));
  },
  watch: {
    audioStatus(v) {
      setTimeout(() => {
        try {
          v ? audio.play() : audio.pause();
        } catch (e) {
          // console.log(e);
        }
      });
    },
  },
  methods: {
    onPauseHandler() {
    },
    endedHandler() {
      setTimeout(() => {
        this.$store.commit("updateAudioTime", 0);
        this.$store.commit("setAudioStatus", {
          playStatus: false,
          ended: true,
        });
      }, 500);
    },
    onPlayHandler() {
    },
    onTimeupdateHandler(e) {
      if (this.AudioTime === Math.ceil(e.target.currentTime)) {
        return;
      }
      this.$store.commit("updateAudioTime", Math.ceil(e.target.currentTime));
    },
    onLoadedmetadataHandler() {
      //    ('暂停回调', e)
    },
    canplayHandler(e) {
      this.$bus.$emit("setTotalTime", Math.round(e.target.duration));
      if (this.audioStatus) {
        audio.play();
      }
    },
  },
  destroyed() {
    this.$bus.$off("changePlayStatus");
  },
};
</script>
    
<style lang='' scoped>
</style>