<template>
  <!-- 开关组件 -->
  <div class="myswitch">
    <div
      @click="toggle"
      class="out"
      :style="{
        borderRadius: borderRadius
      }"
    >
      <div
        class="inner-item"
        :style="{
          borderRadius: borderRadius,
          background: visible==leftValue?'#7F76BD':'#fff',
          color: visible==leftValue?'#fff':'',
          marginRight: '4px'
        }"
      >
        {{ leftText }}
      </div>
      <div
        class="inner-item"
        :style="{
          borderRadius: borderRadius,
          background: visible==rightValue?'#7F76BD':'#fff',
          color: visible==rightValue?'#fff':''
        }"
      >
        {{ rightText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: 1
    },
    borderRadius: {
      default: '4px'
    },
    leftText: {
      default: '左'
    },
    rightText: {
      default: '右'
    },
    rightValue: {
      default: 2
    },
    leftValue: {
      default: 1
    }
  },
  data () {
    return {
      visible: this.value
    }
  },
  watch: {
    value (val) {
      this.visible = val
    },
    visible (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    toggle () {
      if (this.visible == this.leftValue) {
        this.visible = this.rightValue
      } else {
        this.visible = this.leftValue
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.myswitch {
  .out {
    cursor: pointer;
    height: 24px;
    width: auto;
    border: 1px solid rgba(221,218,236,1);
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 2px;
    .inner-item {
      height: 20px;
      line-height: 20px;
      width: 50%;
      font-size: 14px;
      padding: 0 5px;
    }
  }
}
</style>