import Vue from 'vue'
import Vuex from 'vuex'
import bus from '../eventBus'
import scenario from './modules/scenario'
import { GetRequest } from '@/config/request'
import { toEnum } from '@tools'
const getRequest = new GetRequest()

Vue.use(Vuex)

const dictObj = toEnum({
  'list1': 'sys_scenario_style',
  'list2': 'sys_scenario_age',
  'list3': 'sys_user_gender',
  'list4': 'sys_scenario_type',
  'list5': 'sys_scenario_difficulty',
  'audioType': 'sys_scenario_audio_type',
  'audioTimeLength': 'sys_scenario_audio_time_length'
})

export default new Vuex.Store({
  state: {
    dict: {
      list1: [],
      lsit2: [],
      list3: [],
      list4: [],
      list5: [],
      audioType: null,
      audioTimeLength: null
    },
    loading: false,
    playNum: 0,  //游戏人数,
    scenarioList: [],  //剧本列表信息
    authMenu: null,
    permissions: null,
    userMsg: {},
    copyScript: null, // 剧本复制
    firstChapterId: '', // 第一幕的chapterId
    firstPersonId: '', // 第一幕的scenarioPersonId

    audioSrc: '',
    audioStatus: false,
    audioTime: '',
    uid: 0,
    curAudioId: 0,
  },
  mutations: {
    // 字典表
    setDict(state, data) {
      state.dict = { ...state.dict, ...data }
    },
    setLoading(state, bol) {
      state.loading = bol
    },
    setPlayNum(state, playNum) {
      state.playNum = playNum
    },
    setFirstChapterId(state, id) {
      state.firstChapterId = id
    },
    setFirstPersonId(state, id) {
      state.firstPersonId = id
    },
    setScenarioList(state, scenarioList) {
      state.scenarioList = scenarioList
    },
    setAuthMenu(state, authMenu) {
      state.authMenu = authMenu
    },
    setPermissions(state, data) {
      const { auths, userMsg } = data
      state.permissions = auths
      state.userMsg = userMsg
    },
    // 新增权限
    putPermissions(state, field) {
      state.permissions = Object.assign(state.permissions, field)
    },
    // 设置复制剧本
    setCopyScript(state, data) {
      state.copyScript = data
    },
    // todo
    setAudioStatus(state, audioItem) {
      if (audioItem.changeSrc) {
        state.audioSrc = audioItem.audioUrl
        state.curAudioId = audioItem.uid
        return
      }
      state.audioStatus = audioItem.playStatus
      if (!audioItem.ended) {
        state.audioSrc = audioItem.audioUrl
        state.curAudioId = audioItem.uid
      }
      bus.$emit("changePlayStatus", audioItem.id);
    },
    updateAudioTime(state, audioTime) {
      state.audioTime = audioTime
    },
    setUid(state, uid) {
      state.uid = uid
    },
   
  },
  actions: {
    setDict({ commit }, obj) {
      commit('setDict', obj)
    },
    setLoading({ commit }, bol) {
      commit('setLoading', bol)
    },
    // 获取权限
    async getPermissions({ commit }) {
      const res = await getRequest.getUserMsgAuth();
      if (res.respCode === 0) {
        const userMsg = res.datas
        let permissions = userMsg.permissions;
        const auths = permissions.reduce((permission, cur) => {
          const [name, auth] = cur.split(":");
          if (!permission[name]) {
            permission[name] = {};
          }
          permission[name][auth] = true;
          return permission;
        }, {});
        commit('setPermissions', { auths, userMsg })
      }
    },

    // 获取菜单
    async getMenuList({ commit }) {
      const menu = await getRequest.getMenuList();
      commit('setAuthMenu', menu)
    },
    // 获取字典表
    async getDicts({ commit }, param) {
      // 获取字典表字段
      param = param || ['list1', 'list2', 'list3', 'list4', 'list5']
      let obj = param.reduce((p, c) => (p[dictObj[c]] = c, p), {});

      const res = await getRequest.getDicts([...Object.keys(obj)].join(','))
      const dict = res.datas.reduce((pre, cur) => {
        const dictType = obj[cur.dictType]
        if (!pre[dictType]) {
          pre[dictType] = []
        }
        pre[dictType].push(cur)
        return pre
      }, {})
      commit('setDict', dict)
    },
    // 获取剧本
    async getScenarioList({ commit }) {
      const data = {
        isAudit: 0,
        searchName: "",
        time: "",
        page: 1,
        limit: 10000,
      }
      const res = await getRequest.getScenarioAll(data);
      commit('setScenarioList', res.data)
    },
  
  },
  modules: {
    scenario
  }
})
