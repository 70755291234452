import http from './http.js'
import { Message } from 'element-ui';

/***
 * 请求
 * @param {String} url 请求地址
 * @param {Object} data 参数
 * @param {Object} config.prompt 成功是否弹出后端返回信息  其他则是axios默认配置
 */

export const get = (url, data, config) => {
    return sendApi('get', [url, {
        params: data
    }, config])
}
export const post = (url, data, config) => {
    return sendApi('post', [url, data, config])
}
export const put = (url, data, config) => {
    return sendApi('put', [url, data, config])
}
export const del = (url, data, config) => {
    const params = typeof data === 'object' ? [url, data] : [`${url}/${data}`]
    return sendApi('delete', [...params, config])
}

function sendApi(operation, configure) {
    return new Promise((resolve, reject) => {
        http[operation](...configure).then(res => {
            const index = operation === 'delete' && !configure[2] ? 1 : 2
            if (!res.data.respCode) {
                configure[index] && configure[index].prompt && Message.success(res.data.respMsg)
                resolve(res.data.datas || res.data)
            } else {
                !configure[index].noWarning && Message.warning(res.data.respMsg)
                reject(res)
            }
        }).catch(error => {
            Message.error(error.respMsg || error.error || '接口报错')
            reject(error)
        })
    })
}

