<!--  -->
<template>
  <div class="home-content">
    <div class="content">
      <span class="row1"> 公告：提前上传剧本所需图片，能让审核速度更快 </span>
      <div class="row2">
        <el-menu
          router
          :unique-opened="true"
          active-text-color="#fff"
          text-color="#999999"
          default-active="1"
          class="menu-content"
          style="
            width: 20%;
            padding: 35px;
            box-sizing: border-box;
            height: calc(100vh - 60px - 120px);
            overflow: hidden;
            overflow-y: scroll;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          "
        >
          <div
            class="menu-item"
            v-for="items in navigationList"
            :key="items.id"
          >
            <el-submenu v-if="items.subMenus" :index="items.id">
              <template slot="title">
                <i :class="items.css || 'el-icon-location'"></i>
                <span>{{ items.name }}</span>
              </template>
              <el-menu-item
                class="item-child"
                v-for="item in items.subMenus"
                :key="item.id"
                :index="item.path"
                >{{ item.name }}</el-menu-item
              >
            </el-submenu>

            <el-menu-item v-else :index="items.path">
              <i class="iconfont my-icon" :class="items.css"></i>
              <span slot="title">{{ items.name }}</span>
            </el-menu-item>
          </div>
        </el-menu>
        <div class="row2-right">
           <!-- <transition name="fade-transform" mode="out-in"> -->
            <keep-alive>
              <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive"></router-view>
          <!-- </transition> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    navigationList() {
      const menu = this.$store.state.authMenu;
      return menu;
    },
  },
  mounted() {},

  methods: {
    goto(path) {
      this.$router.push(path);
    },
  },
};
</script>
    
<style lang="scss" scoped>
@import '../../style//transtion';
@import './layout.scss';
</style>
