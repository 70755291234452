<!--  -->
<template>
  <el-card class="home-headers">
    <div class="headers-content">
      <span>
        <span style="color: rgba(198, 193, 224, 1)">吃瓜神探</span>
        <span style="margin-left: 30px">用户名: {{ nickname }}</span>
      </span>

      <span style="display: flex">
        <el-button
          style="
            margin-left: 10px;
            border: 1px solid rgba(250, 225, 238, 1);
            background: rgba(250, 225, 238, 1);
            color: #333;
          "
          class="m-r-10"
          size="mini"
          @click="openDialog('compensate')"
          >补偿福利</el-button
        >
        <div class="m-r-10" v-hasAuth="'picture-uplaod'">
          <upload-image />
        </div>
        <div v-hasAuth="'picture-uplaod'">
          <upload-audio />
        </div>
        <el-button
          style="
            margin-left: 10px;
            border: 1px solid rgba(250, 225, 238, 1);
            background: rgba(250, 225, 238, 1);
            color: #333;
          "
          type="warning"
          size="mini"
          @click="newSenor"
          v-hasAuth="'scenario-add'"
          >新建剧本</el-button
        >
        <!-- <el-button size="mini" style="margin-left: 10px" @click="modifyPassword"
          >修改密码</el-button
        > -->
        <passwordDialog/>
        <el-button size="mini" style="margin-left: 10px" @click="unLogin"
          >退出登录</el-button
        >
      </span>
    </div>
    <compensate ref="compensate" />
  </el-card>
</template>

<script>
import uploadImage from "@/components/common/uploadImage";
import uploadAudio from "@/components/common/uploadAudio";
import compensate from "@/components/common/compensate";
import passwordDialog from './passwordDialog.vue'

export default {
  name: "Home",
  components: {
    uploadImage,
    compensate,
    uploadAudio,
    passwordDialog
  },
  data() {
    return {
      content: "",
      type: 1,
      activeIndex: "/home",
      shopType: 1,
     
    };
  },
  async mounted() {},
  computed: {
    navigationList() {
      const menu = this.$store.state.authMenu;
      return menu;
    },
    nickname() {
      return this.$store.state.userMsg.nickname;
    },
  },
  methods: {
    newSenor() {
      // 新建剧本令保存按钮显示
      sessionStorage.setItem("privateFlag", true);
      sessionStorage.setItem("showTipFlag", false);
      localStorage.removeItem("scenarioId");
       sessionStorage.scenarioName = '新剧本'
      this.$router.push({ name: "entry" });
    },
    unLogin() {
      this.$router.replace({ name: "login" });
    },
    goto(path) {
      this.$router.push(path);
    },
    openDialog(ref) {
      this.$refs[ref].showDialog = true;
    },
  },
};
</script>
    
<style lang="scss" scoped>
@import './layout';
</style>
