<template>
  <div id="app">
    <router-view></router-view>
    <!-- 全局音频模块 -->
    <CommonAudio />
  </div>
</template>

<script>
import CommonAudio from "./components/common/commonAudio";
export default {
  name: "app",
  components: {
    CommonAudio,
  },
  data() {
    return {
      model: {
        isAudit: 0,
        searchName: "",
        time: "",
        page: 1,
        limit: 50,
      },
    };
  },
  created() {
    // this.putStore();
    localStorage.ignoreMinute =  localStorage.ignoreMinute || 5
    this.init();
  },
  methods: {
    async init() {
      // await是为了401的时候别全部请求，当第一个请求401时后面便不会执行
      //  this.$store.dispatch("getPermissions");
      //  this.$store.dispatch("getMenuList");
      this.$store.dispatch("getDicts");
      this.$store.dispatch("getScenarioList");
      // 数据统计筛选默认值
      localStorage.ignoreMinute =  localStorage.ignoreMinute || 5
    },
    // 使vuex持久化
    putStore() {
      //在页面加载时读取sessionStorage里的状态信息
      if (sessionStorage.getItem("store")) {
        this.$store.replaceState(
          Object.assign(
            {},
            this.$store.state,
            JSON.parse(sessionStorage.getItem("store"))
          )
        );
      }
      //在页面刷新时将vuex里的信息保存到sessionStorage里
      window.addEventListener("beforeunload", () => {
        const store = this.$store.state;
        store.authMenu = null;
        sessionStorage.setItem("store", JSON.stringify(this.$store.state));
      });
    },
  },
};
</script>