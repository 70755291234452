import { GetRequest } from '@/config/request'
const getRequest = new GetRequest()
export default {
    namespaced: true,
    state: {
        allClueList: [],
        labelList: []  //线索标签
    },
    mutations: {
        setAllClueList(state, list) {
            state.allClueList = list
        },
        setScenarioLabel(state, labelList) {
            state.labelList = labelList
        },
    },
    actions: {
        async getAllClueList({ commit }, id) {
            const res = await getRequest.getScenarioClue(id);
            if (res.respCode === 0) {
                commit('setAllClueList', res.datas)
            }
        },
        // 获取剧本线索标签
        async getScenarioLabel({ commit }, scenarioId) {
            const res = await getRequest.getScenarioLabel({
                scenarioId
            });
            if (res.respCode === 0) {
                const allClueList = res.datas.sort((x, y) => {
                    return x.chapterNum - y.chapterNum;
                });
                commit('setScenarioLabel', allClueList)
            }
        }
    },
    getters: {
        // clueLabelList(state, getters, rootState, rootGetters) {
        //     return state.jobTitle + " aka " + rootState.job
        // }
        clueLabelList({ allClueList, labelList }) {
            labelList = JSON.parse(JSON.stringify(labelList));
            labelList.forEach(item => item.linkClue = [])
            labelList.push({ labelName: "未关联线索", id: "0", linkClue: [] });
            allClueList.forEach(clue => {
                const item = labelList.find(v => clue.labelId === v.id)
                item && (item.linkClue
                    ? (item.linkClue.push(clue))
                    : (item.linkClue = [clue]))
            });
            return labelList

        }
    }

}