import {
    post
} from '../interface'

// 获取商品列表
async function addCompensate(params) {
    return await post('/api-user/welfare/compensation', params, {
        prompt: true
    })
}

export default {
    addCompensate
}