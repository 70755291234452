import shop from './shop'
import home from './home'
import message from './message'
import chiguaData from './chiguaData'
import systemConfig from './systemConfig'
import source from './source'
import market from './market'
import page from './page'
import log from './log'
import scenario from './scenario'
import reward from './reward'

export default {
    shop,
    home,
    message,
    chiguaData,
    source,
    systemConfig,
    market,
    page,
    log,
    scenario,
    reward
}