<template>
  <!-- 登录页 -->
  <div class="login">
    <el-card class="headers">
      <span style="color: rgba(198, 193, 224, 1)">吃瓜神探</span>
    </el-card>
    <div class="content">
      <el-card class="login-card">
        <el-form :model="user" :rules="rules" ref="user">
          <el-form-item label="账号" label-width="100" prop="username">
            <el-input
              v-model="user.username"
              placeholder="请向编辑申领账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" label-width="100" prop="password">
            <el-input
              type="password"
              v-model="user.password"
              placeholder=""
              @keyup.enter.native="login"
            ></el-input>
          </el-form-item>
        </el-form>

        <div class="login-btn">
          <el-button
            type="primary"
            v-loading="loading"
            :disabled="loading"
            @click="login"
            >登录</el-button
          >
        </div>
      </el-card>
      <div style="position: absolute; bottom: 20px; width: 100%; text-align: center; color: red; font-size: 14px;">
      <a href="https://beian.miit.gov.cn/" target="_blank">
        <span>粤ICP备20058821号</span>
      </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        username: "",
        password: "",
        grant_type: "password",
      },
      rules: {
        username: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      loading: false,
    };
  },
  created() {
    localStorage.clear();
  },
  watch: {
    loading(val) {
      if (val) {
        setTimeout(() => {
          this.loading = false;
        }, 5000);
      }
    },
  },
  methods: {
    async login() {
      this.loading = true;
      this.$refs.user.validate((valid) => {
        if (valid) {
          // this.$router.push({path: '/'})

          const obj = new FormData();
          Object.keys(this.user).map((key) => {
            obj.append(key, this.user[key]);
          });
          this.getRequest.login(obj).then(async (res) => {
            if (res.respCode === 0) {
              this.$message({
                message: "登录成功！",
                type: "success",
              });
              localStorage.setItem("access_token", res.datas.access_token);
              let dispatchArr = [
                "getPermissions",
                "getMenuList",
                "getDicts",
                "getScenarioList",
              ];
              dispatchArr.forEach((item) => this.$store.dispatch(item));
              this.$router.replace({ path: "/home/works" , params: {
                refresh: true
              }});
            } else {
              this.$message({
                message: res.respMsg,
                type: "error",
              });
            }
            this.loading = false;
          });
        } else {
          this.loading = false;
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.headers {
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 100;
  padding-left: 10%;
}
.content {
  background-image: url("../../assets/bg01_img_n.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  width: 100%;
  // background: RGBA(236, 234, 250, 1);
  position: relative;
  .login-card {
    right: 10%;
    top: 20%;
    position: absolute;
    width: 400px;
    height: 300px;
  }
  .login-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>