<template>
  <el-dialog  :append-to-body="true"  top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px"> 补偿福利 </span>
      <!-- <el-button class="slot-button" size="mini">保存并复制</el-button> -->
    </div>
    <div class="dialog">
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>目标用户
        </div>
        <el-select size="mini" v-model="form.userType">
          <el-option
            v-for="option in selectArr.limitPeople"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex-row flex-as">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>内容
        </div>
        <div style="position: relative">
          <div
            class="flex-row flex-ac"
            v-for="(item, index) in form.welfareContentDTOList"
            :key="index"
          >
            <el-select size="mini" v-model="item.productIdOrDisCountId">
              <el-option
                v-for="option in goods"
                :key="option.id"
                :label="option.productName"
                :value="option.id"
              ></el-option>
              <el-option
                v-for="option in coupon"
                :key="option.id"
                :label="option.discountsName"
                :value="option.id"
              ></el-option>
            </el-select>
            <el-input
              v-model.number="item.quantity"
              style="width: 70px; margin: 0 30px"
              size="mini"
              oninput="value=value.replace(/[^\d]/g,'')"
            >
              <span slot="suffix" style="display: block; margin-top: 5px"
                >件</span
              >
            </el-input>
            <img
              @click="deleteContent(index)"
              class="pointer"
              src="../../assets/del_btn_n.png"
              alt=""
            />
          </div>
          <el-button @click="addContent" size="mini" class="content-add"
            >添加</el-button
          >
        </div>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>推送位置
        </div>
        <el-select size="mini" v-model="form.pushLocal">
          <el-option
            v-for="option in selectArr.pushLocal"
            :key="option.value"
            :value="option.value"
            :label="option.label"
          >
          </el-option>
        </el-select>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>公告文本
        </div>
        <el-input
          v-model="form.noticeContent"
          style="width: 430px"
          size="mini"
          type="textarea"
          placeholder="请输入公告文本"
        ></el-input>
      </div>
      <div class="flex-row flex-as p-r">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>弹窗文本
        </div>
        <el-input
          v-model="form.alertContent"
          style="width: 430px"
          size="mini"
          type="textarea"
          placeholder="请输入弹窗文本"
        ></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="mini" plain type="danger" @click="deleteCompensate"
        >删除</el-button
      >
      <el-button
        :loading="loadingArr[0]"
        size="mini"
        type="danger"
        @click="addCompensate(0)"
        >保存未发售</el-button
      >
      <el-button
        :loading="loadingArr[2]"
        size="mini"
        type="info"
        plain
        @click="addCompensate(2)"
        >停售</el-button
      >
      <el-button
        :loading="loadingArr[1]"
        size="mini"
        type="primary"
        @click="addCompensate(1)"
        >立即发售</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { formRule } from "@tools";
export default {
  props: {
    detail: {
      default: null,
    },
  },
  data() {
    this.formRule = [
      {
        label: "userType",
        name: "目标用户",
        rule: {
          required: true,
        },
      },
      {
        label: "welfareContentDTOList",
        name: "内容",
        rule: {
          emptyArr: true
        },
      },
      {
        label: "pushLocal",
        name: "推送位置",
        rule: {
          required: true,
        },
      },
      {
        label: "noticeContent",
        name: "公告文本",
        rule: {
          required: true,
          noNegative: true,
        },
      },
      {
        label: "alertContent",
        name: "弹窗文本",
        rule: {
          required: true,
          zero: true,
        },
      }
    ];
    this.selectArr = {
      limitPeople: [
        { label: "所有人", value: 1 },
        { label: "首充用户", value: 2 },
      ],
      pushLocal: [
        { label: "公告", value: 1 },
        { label: "直接发放", value: 2 },
      ],
    };
    return {
      showDialog: false,
      goods: [],
      coupon: [],
      form: {
        alertContent: "",
        grantStatus: 0,
        noticeContent: "",
        pushLocal: 1,
        userType: 1,
        welfareContentDTOList: [
          {
            productIdOrDisCountId: "",
            quantity: 1,
            type: 0,
          },
        ],
      },

      loadingArr: [false, false, false, false],
    };
  },
  created() {
    this.getGoodsAndCoupon();
  },
  methods: {
    async addCompensate(op) {
      if (!formRule(this.form, this.formRule)) return;
      this.$set(this.loadingArr, op, true);
      await this.$api.home[this.detail ? "editCompensate" : "addCompensate"](
        this.checkParams(op)
      ).finally(() => {
        this.$set(this.loadingArr, op, false);
        this.showDialog = false;
      });
      //   this.$emit("getShopList");
    },
    checkParams(op) {
      this.form.grantStatus = op;
      this.form.welfareContentDTOList = this.form.welfareContentDTOList.filter(
        (item) => {
          item.type = this.goods.find(o => o.id === item.productIdOrDisCountId) ? 1 : 2
          return item.productIdOrDisCountId !== "" && item.quantity !== 0;
        }
      );
      return this.form
    },
    async getGoodsAndCoupon() {
      const params = { limit: -1, page: 1 };
      const { data: goods } = await this.$api.shop.getShopList(params);
      const { data: coupon } = await this.$api.shop.getDiscountList(params);
      this.goods = goods;
      this.coupon = coupon;
    },
    addContent() {
      this.form.welfareContentDTOList.push({
        productIdOrDisCountId: '',
        quantity: 1,
        type: 0,
      });
    },
    deleteContent(index) {
      this.form.welfareContentDTOList.splice(index, 1);
    },
    deleteCompensate () {

    }
  },
  computed: {
    discount() {
      const form = this.form;
      return form.originalPrice === 0
        ? 0
        : ((form.nowPrice / form.originalPrice) * 10).toFixed(1);
    },
  },
  watch: {
    showDialog(newVal) {
      !newVal && (this.form = this.$options.data().form);
      newVal && this.getGoodsAndCoupon();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
</style>