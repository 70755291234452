<template>
  <!-- 顶部栏 -->
  <div class="home">
    <Navbar />
    <Sidebar />
  </div>
</template>

<script>
import Navbar from "./compts/Navbar.vue";
import Sidebar from "./compts/Sidebar.vue";

export default {
  name: "Home",
  components: {
    Sidebar,
    Navbar,
  },
  data() {
    return {
      content: "",
      type: 1,
      activeIndex: "/home",
      shopType: 1,
      passwordForm: {
        oldPassword: "",
        newPassword: "",
      },
      passwordDialog: false,
    };
  },
  async mounted() {},
  computed: {
    navigationList() {
      const menu = this.$store.state.authMenu;
      return menu;
    },
    nickname() {
      return this.$store.state.userMsg.nickname;
    },
  },
  methods: {
    modifyPassword() {
      this.passwordDialog = true;
      this.passwordForm = this.$options.data().passwordForm;
    },
    async setPassword() {
      const res = await this.getRequest.setPassword(this.passwordForm);
      let code = res.respCode === 0;
      this.$message[code ? "success" : "error"](res.respMsg);
      code && (this.passwordDialog = false);
    },
    newSenor() {
      // 新建剧本令保存按钮显示
      sessionStorage.setItem("privateFlag", true);
      sessionStorage.setItem("showTipFlag", false);
      localStorage.removeItem("scenarioId");
      this.$router.push({ name: "entry" });
    },
    unLogin() {
      this.$router.replace({ name: "login" });
    },
    goto(path) {
      this.$router.push(path);
    },
    openDialog(ref) {
      this.$refs[ref].showDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './compts//layout'
</style>
