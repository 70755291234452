var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"myswitch"},[_c('div',{staticClass:"out",style:({
      borderRadius: _vm.borderRadius
    }),on:{"click":_vm.toggle}},[_c('div',{staticClass:"inner-item",style:({
        borderRadius: _vm.borderRadius,
        background: _vm.visible==_vm.leftValue?'#7F76BD':'#fff',
        color: _vm.visible==_vm.leftValue?'#fff':'',
        marginRight: '4px'
      })},[_vm._v(" "+_vm._s(_vm.leftText)+" ")]),_c('div',{staticClass:"inner-item",style:({
        borderRadius: _vm.borderRadius,
        background: _vm.visible==_vm.rightValue?'#7F76BD':'#fff',
        color: _vm.visible==_vm.rightValue?'#fff':''
      })},[_vm._v(" "+_vm._s(_vm.rightText)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }