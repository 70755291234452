<template>
  <!-- 上传图片弹窗 -->
  <div class="uploadImage">
    <el-button
      style="
        border: 1px solid rgba(197, 192, 224, 1);
        background: rgba(197, 192, 224, 1);
        color: #333;
      "
      type="warning"
      size="mini"
      @click="addPicture"
      >上传音视频</el-button
    >

    <el-dialog
      :visible.sync="dialog"
      align="center"
      title="上传音视频"
      :append-to-body="true"
      width="60%"
      :close-on-click-modal="false"
    >
      <div v-if="dialog" class="row2">
        <div
          v-for="(item, i) in scList"
          :key="i"
          style="padding: 0 10px; width: 45%; margin-bottom: 50px"
        >
          <div style="display: flex; justify-content: center">
            <UploadItem
              :uploadType="1"
              @uploadSuccess="uploadSuccess"
              :item="item"
              :type="2"
              class="avatar-uploader"
            >
              <AudioItem :audioItem="item" :isShowTips="!item.audioUrl" />
            </UploadItem>
          </div>

          <div
            style="
              display: flex;
              justify-content: center;
              margin-top: 20px;
              align-items: center;
            "
          >
            <el-tag size="small" style="width: 96px" effect="dark">名称</el-tag>
            <el-input
              v-model="item.audioName"
              placeholder="请输入素材名"
              size="mini"
              style="width: 200px; margin-left: 12px"
            ></el-input>
          </div>
          <div
            style="
              display: flex;
              justify-content: center;
              margin-top: 8px;
              align-items: center;
            "
          >
            <el-tag size="small" style="width: 96px" effect="dark"
              >关联剧本</el-tag
            >
            <el-select
              v-model="item.scenarioId"
              style="width: 200px; margin-left: 12px"
              size="mini"
              placeholder="剧本"
            >
              <el-option
                v-for="(option, oi) in scenarioList"
                :key="oi"
                :label="option.scenarioName"
                :value="option.id"
              ></el-option>
            </el-select>
          </div>
          <div style="display: flex; justify-content: center; margin-top: 8px">
            <el-select
              v-model="item.type"
              style="width: 100px"
              size="mini"
              placeholder="类别"
            >
              <el-option
                v-for="(option, oi) in audioTypeList"
                :key="oi"
                :label="option.dictLabel"
                :value="option.dictValue"
              ></el-option>
            </el-select>

            <el-select
              style="width: 100px; margin-left: 10px"
              size="mini"
              placeholder="时长"
              v-model="item.timeLength"
            >
              <el-option
                v-for="(option, oi) in audioTimeLengthList"
                :key="oi"
                :label="option.dictLabel"
                :value="option.dictValue"
              ></el-option>
            </el-select>

            <el-select
              style="width: 100px; margin-left: 10px"
              size="mini"
              placeholder="题材"
              v-model="item.theme"
            >
              <el-option
                v-for="(option, oi) in list1"
                :key="oi"
                :label="option.dictLabel"
                :value="option.dictValue"
              ></el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="row3">
        <el-button
          v-loading="loading"
          :disabled="loading"
          type="primary"
          @click="sureUpload"
          >确认上传</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AudioItem from "./audioItem";
import UploadItem from "@compts/common/UploadItem";
export default {
  components: {
    AudioItem,
    UploadItem,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      loadingTimer: null,
      accessToken: localStorage.getItem("access_token"),
      scList: [],
    };
  },
  created() {
    this.createdList();
    if (!this.$store.state.dict.audioType) {
      this.$store.dispatch("getDicts", ["audioType", "audioTimeLength"]);
    }
  },
  computed: {
    ...mapState(["scenarioList"]),
    list1() {
      return this.$store.state.dict.list1;
    },
    list2() {
      return this.$store.state.dict.list2;
    },
    list3() {
      return this.$store.state.dict.list3;
    },
    list4() {
      return this.$store.state.dict.list4;
    },
    audioTypeList() {
      return this.$store.state.dict.audioType;
    },
    audioTimeLengthList() {
      return this.$store.state.dict.audioTimeLength;
    },
  },
  watch: {
    loading(val) {
      if (val) {
        this.loadingTimer = setTimeout(() => {
          this.loading = false;
        }, 10000);
      } else {
        clearTimeout(this.loadingTimer);
      }
    },
  },
  methods: {
    addPicture() {
      this.dialog = true;
      this.scList = [];
      this.createdList();
    },
    createdList() {
      // 创建图片上传对象
      for (let i = 0; i < 10; i++) {
        this.scList.push({
          audioName: "",
          audioTotalTime: 0,
          fileInfoId: "",
          audioUrl: "", // 图片路径
          scenarioId: "", // 素材名
          theme: "", // 类别ID
          timeLength: "", // 性别ID
          type: "", // 年龄ID
        });
      }
    },
    uploadSuccess(item, v) {
      const res = v.datas;
      item.audioTotalTime= 0;
      item.audioUrl = res.url;
      item.fileInfoId = res.id;
    },
    async sureUpload() {
      let beforeList = [];
      let next = true;
      this.scList.forEach((ele, ei) => {
        if (ele.fileInfoId) {
          const lint = {
            audioName: "素材",
            scenarioId: "剧本类型",
            theme: "题材",
            timeLength: "时长类型",
            type: "类型",
          };
          Object.keys(lint).every((item) => {
            if (!ele[item]) {
              this.$message.error(`请输入第${ei + 1}位${lint[item]}`);
              next = false;
            }
            return next;
          });
          beforeList.push(ele);
        }
      });
      if (!next) return;
      beforeList.forEach((item) => {
        item.audioTotalTime = Math.round(item.audioTotalTime);
        item.theme = +item.theme;
        item.timeLength = +item.timeLength;
        item.type = +item.type;
      });

      this.loading = true;
      const res = await this.$api.source.uploadAudio(beforeList);
      if (res.respCode === 0) {
        this.$message({
          message: "素材上传成功！",
          type: "success",
        });
        this.dialog = false;
        this.scList = [];
        this.createdList();
      } else {
        this.$message({
          message: res.respMsg,
          type: "error",
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 150px;
}
.avatar-uploader .el-upload:hover {
  border-color: #7f76bd;
}
.avatar {
  width: 198px;
  height: 148px;
  display: block;
}
</style>

<style lang='scss' scoped>
.row1 {
  display: flex;
  justify-content: center;
  .title {
    background: rgba(236, 234, 250, 1);
    border-radius: 4px;
    width: 100px;
    height: 24px;
    text-align: center;
    color: #333;
    line-height: 24px;
    font-weight: bold;
  }
}
.row2 {
  display: flex;
  flex-wrap: wrap;
  height: 55vh;
  overflow-y: scroll;
  justify-content: space-between;
}
.row3 {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
.scgl-item-image {
  width: 100%;
  height: 148px;
  background: rgba(236, 234, 250, 1);
  border-radius: 4px;
  position: relative;
  .scgl-left {
    position: absolute;
    top: -5px;
    left: 0;
  }
  .right {
    position: absolute;
    top: 0;
    right: 0;
    height: 20px;
    width: 54px;
    font-size: 14px;
    color: #fff;
    text-align: center;
    line-height: 20px;
    border-radius: 4px;
  }
  .item-image-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
    color: #fff;
  }
}
</style>