import http from './http.js'
import { post, put } from './interface'

const apis = {
  user: '/api-user/user',
  users: '/users',
  getDict: '/api-user/dict/datas/type', // 字典
  getDicts: '/api-user/dict/datas/types',
  scenario: '/api-scenario/scenario', // 剧本
  picture: '/api-scenario/picture', // 图片
  chapter: '/api-clue/chapter', // 章节

  getTheatreLabelUrl: '/api-clue/chapter/clue/label/theatre', //查询小剧场标签信息
  setBannerMsgUrl: '/api-scenario/exhibition/banner/change', //查询小剧场标签信息

  getBannerMsgUrl: '/api-scenario/exhibition/banner/backstage', //获得展区信息
  delBannerMsgUrl: '/api-scenario/exhibition/banner/', //删除banner整个展区

  getUserMsgAuthUrl: '/api-user/users/current', //获取用户信息权限
  getMenuListUrl: '/api-user/menus/current', //获取菜单列表
  getClientUsersListUrl: '/api-user/users/client', //根据客户端信息获取用户信息列表
  addUserMsgUrl: '/api-user/users', //新增用户信息
  modifyUserStatusUrl: '/api-user/users/updateEnabled', //根据客户端信息获取用户信息列表
  delUserUrl: '/api-user/users/', //根据客户端信息获取用户信息列表
  resetPasswordUrl: '/api-user/users/', //根据客户端信息获取用户信息列表
  // getAllClientMsgUrl: '/api-uaa/clients/list',  //获取所有客户端信息
  getAllClientMsgUrl: '/api-user/roles/all', //获取所有客户端信息
  distributeRolesUrl: '/api-user/roles/distribute/menu', //新建角色及分配菜单权限
  getAllAuthListUrl: '/api-uaa/clients/list', //获取所有客户端权限信息
  delRolesUrl: '/api-user/roles/', //删除权限角色
  getAllsAuthListUrl: '/api-user/menus/alls', //直接获取权限列表
}
class GetRequest {
  // 用户登录
  // async login (obj) {
  //   const { data } = await http.post('/api-uaa/oauth/username/token', obj)
  //   return data
  // }
  async login(obj) {
    const {
      data
    } = await http.post('/api-uaa/oauth/token', obj, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      }
    })
    return data
  }

  // 根据类型集合获取字典信息
  async getDict(str) {
    const {
      data
    } = await http.get(apis.getDict, {
      params: {
        type: str
      }
    })
    return data
  }

  // 同时查询多个字典
  async getDicts(str) {
    const {
      data
    } = await http.get(apis.getDicts, {
      params: {
        types: str
      }
    })
    return data
  }

  // 查询剧本基本信息
  async getScenarioBase(id) {
    const {
      data
    } = await http.get(apis.scenario + `/${id}`)
    return data
  }

  // 新增剧本
  async scenario(obj) {
    const {
      data
    } = await http.post(apis.scenario, obj)
    return data
  }

  // 编辑剧本
  async updateScenario(obj) {
    const {
      data
    } = await http.put(apis.scenario, obj)
    return data
  }

  // 查询人物信息
  async getPerson(id) {
    const {
      data
    } = await http.get(apis.scenario + `/person/${id}`)
    return data
  }

  // 查询剧本人物列表
  async getPersonList(id) {
    const {
      data
    } = await http.get(apis.scenario + `/person/scenario/${id}`)
    return data
  }

  // 查询剧本人物基本信息和幕
  async getPersonDetail(id) {
    const {
      data
    } = await http.get(apis.scenario + `/person/chapter/${id}`)
    return data
  }

  // 新增人物信息
  async addPerson(obj) {
    const {
      data
    } = await http.post(apis.scenario + '/person', obj)
    return data
  }

  // 更新人物信息
  async updatePerson(obj) {
    const {
      data
    } = await http.put(apis.scenario + '/person', obj)
    return data
  }

  // 新增剧本人物幕信息
  async addPersonScenario(obj) {
    const {
      data
    } = await http.post(apis.scenario + '/chapter/person', obj)
    return data
  }

  // 更新剧本人物幕信息
  async updatePersonScenario(obj) {
    const {
      data
    } = await http.put(apis.scenario + '/chapter/person', obj)
    return data
  }

  // 素材列表确认上传
  async surePushPicture(list) {
    const {
      data
    } = await http.post(apis.picture + '/affirm/upload', list)
    return data
  }
  // 素材列表更新
  async updatePicture(list) {
    const {
      data
    } = await put(apis.picture + '/affirm/upload', list, { prompt: true })
    return data
  }

  // 查询个人素材
  async searchPicture(obj) {
    const {
      data
    } = await http.get(apis.picture + '/self/show', {
      params: obj
    })
    return data
  }

  // 查询结局
  async getEnding(id) {
    const {
      data
    } = await http.get(apis.scenario + '/end/' + id)
    return data
  }

  // 新增结局
  async addEnding(obj) {
    const {
      data
    } = await http.post(apis.scenario + '/end', obj)
    return data
  }

  // 更新结局
  async updateEnding(obj) {
    const {
      data
    } = await http.put(apis.scenario + '/end', obj)
    return data
  }
  async getChaptersById(id) {
    const {
      data
    } = await http.get(apis.scenario + '/chapter/scenario/' + id)
    return data
  }

  // 章节列表
  async chapterList(id) {
    const {
      data
    } = await http.get(apis.scenario + '/chapter/list/' + id)
    return data
  }

  // 查询线索
  async getClue(id) {
    const {
      data
    } = await http.get(apis.chapter + '/clue/goods/' + id)
    return data
  }

  // 新增线索
  async addClue(obj) {
    return http.post(apis.chapter + '/clue/goods', obj, { prompt: true })
  }

  // 更新线索
  async updateClue(obj) {
    return await put(apis.chapter + '/clue/goods', obj, { prompt: true })
  }

  // 删除线索
  async deleteClue(id) {
    const {
      data
    } = await http.delete(apis.chapter + '/clue/' + id)
    return data
  }

  // 按幕查询线索列表
  async getChapterClue(id) {
    const {
      data
    } = await http.get(apis.chapter + '/clue/chapter/' + id)
    return data
  }

  // 按剧本查询线索列表
  async getScenarioClue(id) {
    const {
      data
    } = await http.get(apis.chapter + '/clue/scenario/' + id)
    return data
  }

  // 查询单个地点
  async getPlace(id) {
    const {
      data
    } = await http.get(apis.chapter + '/clue/location/' + id)
    return data
  }

  // 新增地点
  async addPlace(obj) {
    const {
      data
    } = await http.post(apis.chapter + '/clue/location', obj)
    return data
  }

  // 更新地点
  async updatePlace(obj) {
    const {
      data
    } = await http.put(apis.chapter + '/clue/location', obj)
    return data
  }

  // 查询地点列表
  async getPlaceList(id) {
    const {
      data
    } = await http.get(apis.scenario + '/chapter/map/' + id)
    return data
  }

  // 更新地点列表
  async updatePlaceList(obj) {
    const {
      data
    } = await http.put(apis.scenario + '/chapter/map', obj)
    return data
  }

  // 查询章节人物列表
  async getChapterPerson(obj) {
    const {
      data
    } = await http.get(apis.scenario + '/person/scenario/clue', {
      params: obj
    })
    return data
  }

  // 查询人身线索
  async getPersonClue(id) {
    const {
      data
    } = await http.get(apis.chapter + '/clue/person/' + id)
    return data
  }

  // 保存人身线索
  async addPersonClue(obj) {
    const {
      data
    } = await http.post(apis.chapter + '/clue/person', obj)
    return data
  }

  // 更新人身线索
  async updatePersonClue(obj) {
    const {
      data
    } = await http.put(apis.chapter + '/clue/person', obj)
    return data
  }

  // 查询剧本列表
  async getScenarioAll(obj) {
    const {
      data
    } = await http.get(apis.scenario + '/all', {
      params: obj
    })
    return data
  }

  // 查询剧本标签
  async getScenarioLabel(obj) {
    const {
      data
    } = await http.get(apis.chapter + '/clue/label/all', {
      params: obj
    })
    return data
  }

  // 新增剧本标签
  async addScenarioLabel(obj) {
    const {
      data
    } = await http.post(apis.chapter + '/clue/label', obj)
    return data
  }

  // 更新剧本标签信息
  async updateScenarioLabel(obj) {
    const {
      data
    } = await http.put(apis.chapter + '/clue/label', obj)
    return data
  }

  // 删除剧本标签
  async deleteScenarioLabel(id) {
    const {
      data
    } = await http.delete(apis.chapter + '/clue/label/' + id)
    return data
  }

  // 单个更新线索标签
  async updateClueLabel(obj) {
    const {
      data
    } = await http.put(apis.chapter + '/clue/update/label', obj)
    return data
  }

  // 复制地图
  async copyMap(obj) {
    const {
      data
    } = await http.post(apis.scenario + '/chapter/copy', obj)
    return data
  }

  // 查询剧中结算
  async getEndingScore(id) {
    const {
      data
    } = await http.get(apis.scenario + '/end/score/all/' + id)
    return data
  }

  // 添加剧中结算
  async addEndingScore(obj) {
    const {
      data
    } = await http.post(apis.scenario + '/end/score/all', obj)
    return data
  }

  // 更新剧中结算
  async updateEndingScore(obj) {
    const {
      data
    } = await http.put(apis.scenario + '/end/score/all', obj)
    return data
  }

  // 新增剧本作者
  async addAuthor(obj) {
    const {
      data
    } = await http.post(apis.scenario + '/author', obj)
    return data
  }

  // 更新剧本作者
  async updateAuthor(obj) {
    const {
      data
    } = await http.put(apis.scenario + '/author', obj)
    return data
  }

  // 删除剧本作者
  async deleteAuthor(id) {
    const {
      data
    } = await http.delete(apis.scenario + '/author/' + id)
    return data
  }

  // 查询作者列表
  async getAuthorList() {
    const {
      data
    } = await http.get(apis.scenario + '/author')
    return data
  }

  // 查询编辑列表
  async getAuthorizedList(obj) {
    const {
      data
    } = await http.get(apis.user + '/authorized', {
      params: obj
    })
    return data
  }

  // 查询小剧场标签信息
  async getTheatreLabel(obj) {
    const {
      data
    } = await http.get(apis.getTheatreLabelUrl, {
      params: obj
    })
    return data
  }

  // 添加修改删除展区Banner
  async changeBanner(obj) {
    const {
      data
    } = await http.post(apis.setBannerMsgUrl, obj)
    return data
  }
  // 添加修改删除展区Banner
  async getBannerMsg(obj) {
    const {
      data
    } = await http.get(apis.getBannerMsgUrl, obj)
    return data
  }
  // 删除banner整个展区
  async delBannerMsg(id) {
    const {
      data
    } = await http.delete(apis.delBannerMsgUrl + id)
    return data
  }

  // 获取用户信息权限
  async getUserMsgAuth() {
    const {
      data
    } = await http.get(apis.getUserMsgAuthUrl)
    return data
  }

  // 获取菜单信息
  async getMenuList() {
    const {
      data
    } = await http.get(apis.getMenuListUrl)
    return data
  }
  // 根据客户端信息获取用户信息列表
  async getClinetUsersList(obj) {
    const {
      data
    } = await http.get(apis.getClientUsersListUrl, {
      params: obj
    })
    return data
  }
  // 新增用户信息
  // async addUserMsg(obj) {
  //   const { data } = await http.post(apis.addUserMsgUrl, obj)
  //   return data
  // }
  // 管理员修改用户状态
  async modifyUserStatus(obj) {
    const {
      data
    } = await http.get(apis.modifyUserStatusUrl, {
      params: obj
    })
    return data
  }
  // 管理员删除用户
  async delUser(id) {
    const {
      data
    } = await http.delete(apis.delUserUrl + id)
    return data
  }

  // 管理员重置密码
  async resetPassword(id) {
    const {
      data
    } = await http.put(apis.resetPasswordUrl + `${id}/password`)
    return data
  }
  // 根据客户端获取角色信息
  async getAllClientMsg(obj) {
    const {
      data
    } = await http.get(apis.getAllClientMsgUrl, {
      params: obj
    })
    return data
  }
  // 根据客户端获取角色信息
  async getAlluthList(obj) {
    const {
      data
    } = await http.get(apis.getAllAuthListUrl, {
      params: obj
    })
    return data
  }

  // 管理员删除角色
  async delRole(id) {
    const {
      data
    } = await http.delete(apis.delRolesUrl + id)
    return data
  }

  // 新建角色及分配菜单权限
  async distributeRoles(obj) {
    const {
      data
    } = await http.post(apis.distributeRolesUrl, obj)
    return data
  }
  // 根据角色ID获取菜单
  async getUserRoleMenus(roleId) {
    const {
      data
    } = await http.get('/api-user/menus/' + `${roleId}/menus?tenantId=internal`)
    return data
  }
  // 获取权限树结构
  async getAllsAuthList() {
    const {
      data
    } = await http.get(apis.getAllsAuthListUrl + `?tenantId=internal `)
    return data
  }
  // 审核
  async scenarioAudit(obj) {
    const {
      data
    } = await put(`/api-scenario/scenario/audit`, obj, { prompt: true })
    return data
  }
  // 管理员重置密码
  async setPassword(obj) {
    const {
      data
    } = await http.put(`/api-user/users/password`, obj)
    return data
  }
  // 新增客户信息
  async addUserMsg(obj) {
    const res = await post(apis.addUserMsgUrl, obj, {
      prompt: true
    })
    return res
  }

  async changePlayStatus(obj) {
    const { data } = await put(`api-scenario/scenario/change/play`, obj, { prompt: true })
    return data
  }
 

}

export {
  GetRequest
}