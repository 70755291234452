import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'
import Home from '../layout'
import login from '../views/login/index.vue'
import store from '../store/index'

Vue.use(VueRouter)

// 重写路由push方法，防止连续跳转同一个路由引起的报错
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const routes = [
  {
    path: '/',
    name: 'login',
    component: Home,
    redirect: "/login",
    children: [
      // {
      //     path: '',
      //     redirect: 'works'
      // },
      // 作品管理
      {
        name: 'works',
        path: '/home/works',
        component: () => import('../views/scenario/scenario.vue'),
        meta: {
          keepAlive: true
        }
      }, 
      {
        path: '/home/source',
        component: () => import('../views/source/scgl.vue')
      }, {
        path: '/home/author',
        component: () => import('../views/author/zzgl')
      },
      {
        path: '/home/shoppingGoods',
        name: 'shoppingGoods',
        component: () => import('../views/shopManager/shopList')
      }, {
        path: '/home/shoppingDiscount',
        component: () => import('../views/shopManager/shopCount')
      }, {
        path: '/home/shoppingOrder',
        component: () => import('../views/shopManager/shopOrder')
      },
      {
        path: '/home/FirstPage',
        component: () => import('../views/pageManager/firstPage.vue')
      },
      {
        path: '/home/scenarioList',
        component: () => import('../views/pageManager/scenarioList.vue')
      },
      // {
      //   path: '/home/User',
      //   component: () => import('../components/home/page/firstPage.vue')
      // },
      {
        path: '/home/insiderperson',
        component: () => import('../views/insiderperson/insiderperson.vue')
      },
      {
        path: '/home/customerperson',
        component: () => import('../views/insiderperson/customerperson.vue')
      },
      {
        path: '/home/comment',
        component: () => import('../views//message/comment.vue')  
      },
      {
        path: '/home/feedback',
        component: () => import('../views//message/feedback.vue')
      },
      {
        path: '/home/dataMain',
        component: () => import('../views/chiguaData/main.vue')
      },
      {
        path: '/home/dataUser',
        component: () => import('../views/chiguaData/user.vue')
      },
      {
        path: '/home/dataBusiness',
        component: () => import('../views/chiguaData/business.vue')
      },
      {
        path: '/home/dataScenario',
        component: () => import('../views/chiguaData/scenario.vue')
      },
      {
        path: '/home/dataCustomized',
        component: () => import('../views/chiguaData/customized.vue')
      },
      {
        path: '/home/video',
        component: () => import('../views/source/sourceVideo.vue')
      },
      {
        path: '/home/dictType',
        component: () => import('../views/systemConfig/dictType.vue')
      },
      {
        path: '/home/dictData',
        component: () => import('../views/systemConfig/dictData.vue')
      },
      {
        path: '/home/systemParams',
        component: () => import('../views/systemConfig/systemParams.vue')
      },
      {
        path: '/home/menus',
        component: () => import('../views/systemConfig/menus.vue')
      },
      {
        name: 'market',
        path: '/home/allMarketing',
        component: () => import('../views/market/allMarketing.vue')
      },
      {
        path: '/home/marketPage',
        component: () => import('../views/market/transferPage.vue')
      },
      {
        path: '/home/rewardManage',
        component: () => import('../views/market/rewardManage.vue')
      },
      {
        path: '/home/userJournal',
        component: () => import('../views/journal/userJournal.vue')
      },
      {
        path: '/home/adminJournal',
        component: () => import('../views/journal/adminJournal.vue')
      },
      {
        path: '/home/screenWords',
        component: () => import('../views/journal/screenWords.vue')
      },
      {
        path: '/home/payLog',
        component: () => import('../views/journal/payLog.vue')
      }
      
     
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/fission',
    name: 'fission',
    component: () => import('../views/market/fission.vue'),
  },
  // 剧本录入
  {
    path: '/entry',
    name: 'entry',
    redirect: '/entey/base',
    // component: () => import(/* 剧本录入 */ '../views/entry1.vue'),
    component: () => import(/* 剧本录入 */ '../views/entry/index.vue'),
    children: [
      {
        name: 'entryBase',
        path: '/entey/base',
        component: () => import('../views/entry/entryBase.vue'),
        meta: {
          keepAlive: true,
          sort:1
        }
      }, 
      {
        name: 'entryPeople',
        path: '/entey/people',
        component: () => import('../views/entry/entryPeople.vue'),
        meta: {
          keepAlive: true,
          sort:2
        }
      }, 
      {
        name: 'entryEvidence',
        path: '/entey/evidence',
        component: () => import('../views/entry/entryEvidence.vue'),
        meta: {
          keepAlive: true,
          sort:3
        }
      }, 
    ]
  }
]


const router = new VueRouter({
  routes
})

// 全局路由导航
router.beforeEach(async (to, from, next) => {
  //   console.log(to, from, store.state.dict,!store.state.permissions)
  if (localStorage.getItem('access_token')) {
    if (!store.state.permissions) {
      await store.dispatch('getPermissions')
    }
    if (!store.state.authMenu) {
      await store.dispatch('getMenuList')
    }
  }
  next()
})

export default router
