import {
    get,
    // put,
    del,
    post
} from '../interface'

// 获取用户忽略列表
async function getUserIgnore(params) {
    return await get(`/api-user/user/ignore`, params)
}
// 添加用户忽略列表
async function addUserIgnore(params) {
    return await post(`/api-user/user/ignore`, params, { prompt: true })
}
// 删除用户忽略列表
async function delUserIgnore(params) {
    return await del(`/api-user/user/ignore`, params)
}
// 搜索用户列表
async function searchUserList(params) {
    return await get(`/api-user/user/ignore/search`, params)
}

// 主要数据列表
async function getMainDataList(params) {
    return await get(`/api-user/count/master/data/table`, params)
}

// 主要数据echart
async function getMainDataChart(params) {
    return await get(`/api-user/count/master/data/chart`, params)
}

// 用户人流->使用分析 列表
async function getUserUseDataList(params) {
    return await get(`/api-user/count/user/flow/use/analysis/table`, params)
}

// 用户人流->使用分析 echart
async function getUserUseDataChart(params) {
    return await get(`/api-user/count/user/flow/use/analysis/chart`, params)
}

// 用户人流->用户画像 列表
async function getUserDrawDataList(params) {
    return await get(`/api-user/count/user/flow/user/portrait/table`, params)
}

// 用户人流->用户画像 echart
async function getUserDrawDataChart(params) {
    return await get(`/api-user/count/user/flow/user/portrait/chart`, params)
}

// 交易支付->整体总览 列表
async function getBusinessWholeDataList(params) {
    return await get(`/api-user/count/transaction/payment/overall/preview/table`, params)
}

// 交易支付->整体总览 echart
async function getBusinessWholeDataChart(params) {
    return await get(`/api-user/count/transaction/payment/overall/preview/chart`, params)
}

// 交易支付->点券排名 列表
async function getBusinessCountDataList(params) {
    return await get(`/api-user/count/transaction/payment/coupon/top/table`, params)
}

// 交易支付->点券排名 echart
async function getBusinessCountDataChart(params) {
    return await get(`/api-user/count/transaction/payment/coupon/top/chart`, params)
}

// 交定制分析 列表
async function getAnalysisDataList(params) {
    return await post(`/api-user/count/customize/made/analysis/table`, params)
}

// 剧本分析->游戏记录表
async function getGameRecordDataList(params) {
    return await get(`/api-user/count/scenario/analysis/game/record/table`, params)
}

// 剧本分析->游戏记录图
async function getGameRecordDataChart(params) {
    return await get(`/api-user/count/scenario/analysis/game/record/chart`, params)
}

// 剧本分析->游戏排名表
async function getGameRankDataList(params) {
    return await get(`/api-user/count/scenario/analysis/game/rank/table`, params)
}

// 剧本分析->销售排名表
async function getSaleRankDataList(params) {
    return await get(`/api-user/count/scenario/analysis/sales/rank/table`, params)
}

// 剧本分析->销售排名表
async function getDetailDataList(params) {
    return await get(`/api-user/count/scenario/analysis/detail/record/table`, params)
}

export default {
    getUserIgnore,
    addUserIgnore,
    delUserIgnore,
    searchUserList,
    getMainDataList,
    getMainDataChart,
    getUserUseDataList,
    getUserUseDataChart,
    getUserDrawDataList,
    getUserDrawDataChart,
    getBusinessWholeDataList,
    getBusinessWholeDataChart,
    getBusinessCountDataList,
    getBusinessCountDataChart,
    getAnalysisDataList,
    getGameRecordDataList,
    getGameRecordDataChart,
    getGameRankDataList,
    getSaleRankDataList,
    getDetailDataList
}