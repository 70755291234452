import {
    get,
    put,
    del,
    post
} from '../interface'

// 获取评论列表
async function getCommentList(params) {
    return await get(`/api-user/scenario/comment/backstage/page`, params)
}

// 移动评论位置
async function moveComment(params) {
    return await put(`/api-user/scenario/comment/backstage/move`, params)
}

// 置顶评论
async function topComment(params) {
    return await put(`/api-user/scenario/comment/backstage/top`, params)
}

// 修改剧透状态
async function changeTransparency(params) {
    return await put(`/api-user/scenario/comment/backstage/spoiler`, params)
}

// 置顶评论
async function deleteComment(id) {
    return await del(`/api-user/scenario/comment/backstage`, id)
}

// 添加评论
async function addComment(params) {
    return await post(`/api-user/scenario/comment/backstage`, params)
}

// 修改评论
async function editComment(params) {
    return await put(`/api-user/scenario/comment/backstage`, params)
}

// 获取反馈列表
async function getFeedbackList(params) {
    return await get(`/api-user/user/feedback/page`, params)
}

// 获取对应剧本的填充评论列表
async function getRandomComment(params) {
    return await get(`/api-user/scenario/comment/fill`, params)
}

// 提交对应剧本的填充评论列表
async function editRandomComment(params) {
    return await post(`/api-user/scenario/comment/fill`, params, { prompt: true })
}

export default {
    getCommentList,
    moveComment,
    topComment,
    changeTransparency,
    deleteComment,
    addComment,
    editComment,
    getFeedbackList,
    getRandomComment,
    editRandomComment
}