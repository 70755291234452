import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins'
import './assets/icon/iconfont.css'
import './style/index.scss'
//ssss
Vue.config.productionTip = false
//请求
import { GetRequest } from '@/config/request'
// import { objDeepCopy } from '@tools'
import http from './config/api/index'

// 派发事件
import bus from './eventBus'
Vue.prototype.$bus = bus

Vue.prototype.getRequest = new GetRequest()
Vue.prototype.$api = http
//请求地址
import { config } from './config/config'
Vue.prototype.$config = config
Vue.prototype.$filesUrl = [
  '/api-file/files-anon',
  '/api-file/files/upload/audio'
]

// 方法
import { timestampToDate } from '@/components/common/time'
Vue.prototype.timestampToDate = timestampToDate
// 富文本
import VueQuillEditor from 'vue-quill-editor'
// import { quillRedefine } from 'vue-quill-editor-upload'
import { quillOptions } from './tools/componentConfig'
Vue.use(VueQuillEditor)
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
Vue.prototype.quillOptions = quillOptions
// 指令
import '@directive'
// 自定义选择框
import myselect from './components/common/myselect.vue'
// 自定义开关
import myswitch from './components/common/myswitch.vue'

Vue.component('myswitch', myswitch)
Vue.component('myselect', myselect)

// import echarts from './tools/initEcharts'

Vue.prototype.beforConfirm = function (fn, { text } = {}, ...query) {
  this.$confirm(text, "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  }).then(fn.bind(this, ...query));
}

// Vue.prototype.$echarts = echarts


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
