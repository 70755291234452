import {
    get, del, post
} from '../interface'

async function getUserVisit (params) {
    return get('/api-file/front/log', params)
}

async function deleteUserVisit(id) {
    return del('/api-file/front/log', id, { prompt: true })
}

async function getScreenWords (params) {
    return get('/api-file/front/screenword', params)
}

async function editScreenWord (params) {
    return post('/api-file/front/screenword', params, { prompt: true })
}

async function deleteScreenWord (id) {
    return del('/api-file/front/screenword', id, { prompt: true })
}

async function getAdminAction (params) {
    return get('/api-file/front/backgroundlog', params)
}

export default {
    getUserVisit,
    deleteUserVisit,
    getScreenWords,
    editScreenWord,
    deleteScreenWord,
    getAdminAction
}