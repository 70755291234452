<!--  -->
<template>
  <div :class="isOnlyProgress ? 'only-progress' : 'audioitem'">
    <!-- 底图 -->
    <div v-if="!isOnlyProgress" class="content">
      <img
        v-if="!isShowTips"
        class="wh"
        style="border-radius: 4px"
        src="../../assets/audioBg.png"
      />
      <!-- 遮罩 -->
      <div v-if="isShowMark" @click="markhandler" class="mark"></div>
      <!-- 上传提示 -->
      <div v-if="isShowTips" class="tips">
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <div>音频要求：</div>
        <div>MP3格式，2M以内大小。</div>
      </div>
      <div class="total-time">
        {{ audiocurtimes(audioItem.audioTotalTime) }}
      </div>
    </div>
    <!-- 进度条 -->
    <div v-if="!isShowTips" class="wh">
      <div
        :style="{ height: isOnlyProgress ? '100%' : '20px' }"
        class="progress-wrap"
      >
        <div
          @click.stop="change()"
          :class="['playBtn', !playStatus ? 'play' : 'pause']"
        >
          <i v-if="!playStatus" class="el-icon-caret-right"></i>
          <div v-if="playStatus"></div>
          <div v-if="playStatus"></div>
        </div>
        <div class="slider" @mousedown.stop="handleTouchStart">
          <div
            :style="{
              width: (curAudioTime / audioItem.audioTotalTime) * 100 + '%',
            }"
            class="slider-fill"
          >
            <div class="slider-thumb"></div>
          </div>
        </div>
        <div class="now-time">
          {{ nowTime() }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
let clientX = 0;
export default {
  components: {},
  props: {
    audioItem: {
      type: Object,
    },
    isShowMark: {
      type: Boolean,
      default: false,
    },
    isShowTips: {
      type: Boolean,
      default: false,
    },
    isOnlyProgress: {
      type: Boolean,
      default: false,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      playStatus: false,
      curAudioTime: 0,
      uid: 0,
    };
  },
  computed: {
    ...mapState(["audioTime", "curAudioId"]),
  },
  mounted() {
    this.uid = this.$store.state.uid + 1;
    this.$store.commit("setUid", this.uid);
    this.changePlayStatusEvent = this.$bus.$on(
      "changePlayStatus",
      this.changePlayStatus
    );
    this.setTotalTimeEvent = this.$bus.$on("setTotalTime", this.setTotalTime);
  },
  watch: {
    audioTime(v) {
      //实时更改进度时间
      const { uid, curAudioId } = this;
      if (uid === curAudioId) {
        this.curAudioTime = v;
      }
    },
    "audioItem.audioUrl"(audioUrl) {
      //切换时更新地址
      this.$store.commit("setAudioStatus", {
        audioUrl,
        uid: this.uid,
        changeSrc: true,
      });
    },
  },
  methods: {
    nowTime() {
      if (!this.isReverse) {
        return this.audiocurtimes(this.curAudioTime);
      } else {
        return this.audiocurtimes(
          +this.audioItem.audioTotalTime - +this.curAudioTime
        );
      }
    },
    setTotalTime(v) {
      if (!this.audioItem.audioTotalTime) {
        const { curAudioId, uid } = this;
        if (curAudioId === uid) {
          this.audioItem.audioTotalTime = v;
        }
      }
    },
    markhandler() {
      this.$emit("markhandler");
    },
    audiocurtimes(curAudioTime) {
      const sec = Math.ceil(curAudioTime) % 60;
      const min = parseInt(curAudioTime / 60);
      return `${min > 9 ? min : "0" + min}: ${sec > 9 ? sec : "0" + sec}`;
    },
    change(v) {
      const { audioItem, playStatus, uid } = this;
      this.$store.commit("setAudioStatus", {
        ...audioItem,
        uid,
        playStatus: v !== undefined ? v : !playStatus,
      });
    },
    changePlayStatus() {
      const { curAudioId, uid } = this;
      const isown = uid === curAudioId;
      this.playStatus = isown ? !this.playStatus : false;
      this.curAudioTime = isown ? this.curAudioTime : 0;
    },
    changeAudioTime(v) {
      this.$bus.$emit("changeCurTime", v);
      this.curAudioTime = v;
    },
    handleTouchStart(e) {
      const { audioItem, curAudioId, uid } = this;
      if (this.audioItem && uid !== curAudioId) return;
      clientX = e.currentTarget.getBoundingClientRect().left;
      let offsetX = e.clientX - clientX;
      let progress = offsetX / 132;
      progress = progress * audioItem.audioTotalTime;
      this.changeAudioTime(progress);
      document.addEventListener("mouseup", this.handleTouchEnd);
      document.addEventListener("mousemove", this.handleTouchMove);
    },
    handleTouchMove(e) {
      let left = (e.clientX - clientX) / 132;
      if (left < 0 || left > 1) {
        return;
      }
      const progress = left * this.audioItem.audioTotalTime;
      this.changeAudioTime(progress);
    },
    handleTouchEnd() {
      document.removeEventListener("mouseup", this.handleTouchEnd);
      document.removeEventListener("mousemove", this.handleTouchMove);
    },
  },
  destroyed() {
    this.$bus.$off("changePlayStatus", this.changePlayStatus);
    this.$bus.$off("setTotalTime", this.setTotalTime);
  },
};
</script>
    
<style lang='scss' scoped>
.progress-wrap {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 20px;
  width: 100%;
  background: #c1c1c1;
  border-radius: 4px;
  .playBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 14px;
    border-radius: 4px;
    font-size: 14px;
    color: #7f76bd;
  }
  .playBtn > div {
    width: 2px;
    height: 8px;
    margin: 0 1px;
    background: #ffffff;
    border-radius: 4px;
  }
  .play {
    background: #ffffff;
  }
  .pause {
    background: #7f76bd;
  }
  .slider {
    position: relative;
    margin-left: 3px;
    width: 128px;
    height: 6px;
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 2px;
    .slider-fill {
      position: relative;
      width: 100%;
      background: #7f76bd;
      height: 4px;
      border-radius: 4px;
    }
    .slider-thumb {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      width: 14px;
      height: 14px;
      background: #c5c0e0;
      border-radius: 50%;
    }
    .slider-thumb::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 10px;
      height: 10px;
      background: #fff;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .now-time {
    font-size: 12px;
    color: #fff;
  }
}
.wh {
  @include wh(100%);
}
.content {
  @include fill();
}
.total-time {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 20px;
  background: rgba(51, 51, 51, 0.5);
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}
.mark {
  width: 100%;
  height: 148px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.mark:hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(51, 51, 51, 0.5);
  height: calc(100% - 18px);
}
.mark:hover::after {
  content: "";
  position: absolute;
  top: 46%;
  left: 52%;
  transform: translate(-50%, -50%);
  background-image: url("../../assets/edit@2x.png");
  background-size: 100% 100%;
  width: 22px;
  height: 24px;
}
.audioitem {
  width: 200px;
  height: 148px;
  border-radius: 4px;
  position: relative;
}
.only-progress {
  @include wh(200px, 28px);
  border-radius: 4px;
  position: relative;
}
.tips {
  border: 1px dashed #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 14px;
  flex-direction: column;
  cursor: pointer;
}
.tips:hover {
  border-color: #7f76bd;
}
</style>