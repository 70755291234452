<!--  -->
<template>
  <el-upload
    class="avatar-uploader"
    :action="url"
    :show-file-list="false"
    :on-success="uploadSuccess"
    :headers="{
      Authorization: `bearer ${accessToken}`,
    }"
    :data="{
    }"
    :before-upload="beforeUpload"
  >
    <slot></slot>
  </el-upload>
</template>

<script>
const FILE_SIZE = 2;

const typeObj = {
  1: ["img", "图片", [" PNG", "JPG", "GIF"]],
  2: ["audio", "音频", ["MP3"]],
};
export default {
  props: {
    type: {
      type: Number,
    },
    uploadType: {
      type: Number,
      default: 0
    },
    item: {
      type: Object,
    }
  },
  data() {
    this.imgTest = ["image/png", "image/jpeg", "image/gif"];
    this.audioTest = ["audio/mpeg"];
    return {
      accessToken: localStorage.getItem("access_token"),
    };
  },
  methods: {
    beforeUpload(file) {
      const [type, text, format] = typeObj[this.type];
      const size = file.size / 1024 / 1024 > FILE_SIZE;
      const test = !this[`${type}Test`].includes(file.type);
      test && this.$message.error(`上传${text}只能是${format}格式!`);
      size && this.$message.error(`上传${text}大小不能超过 2MB!`);
      return !(test || size);
    },
    uploadSuccess(v) {
      this.$emit("uploadSuccess", this.item, v);
    },
  },
  computed: {
    url() {
      const url = this.$config.VUE_APP_URL + this.$filesUrl[this.uploadType]
      return url
    }
  }
};
</script>
    
<style lang='scss' scoped>
</style>