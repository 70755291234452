import {
    post, 
    get,
    put
} from '../interface'

// 获取分页列表
async function getAudioList(params) {
    return await get(`/api-file/material/audio`, params)
}
// 新增剧本音频
async function uploadAudio(params) {
    return await post(`/api-file/material/audio`, params)
}
// 获取分页列表
async function unpdateAudio(params) {
    return await put(`/api-file/material/audio`, params, {
        prompt: true
    })
}


export default{
    getAudioList,
    uploadAudio,
    unpdateAudio
}