import {
    get,
    put,
    del,
    post
} from '../interface'

// 获取链接列表
async function getUrlList(params) {
    return await get('/api-file/manage/marketing/link', params)
}


// 新增链接
async function addUrl(params) {
    return await post('/api-file/manage/marketing/link', params)
}

// 编辑链接
async function editUrl(params) {
    return await put('/api-file/manage/marketing/link', params)
}

// 删除链接
async function delUrl(id) {
    return await del('/api-file/manage/marketing/link', id)
}

// 获取营销中转页
async function getTransferPage() {
    return await get('/api-file/manage/marketing/transfer/list')
}
// 获取营销中转页
async function getTransferPageByPage(params) {
    return await get('/api-file/manage/marketing/transfer/page', params)
}

// 新增，编辑中转页
async function editTransferPage(params) {
    return await post('/api-file/manage/marketing/transfer', params)
}

// 删除中转页
async function delTransferPage(id) {
    return await del('/api-file/manage/marketing/transfer', id, { prompt: true })
}

// 获取有价格的剧本(点券)
async function getHavePriceScenario () {
    return await get('/api-scenario/scenario/marketing')
}

// 裂变列表
async function getFissionList(params) {
    return await get('/api-file/manage/marketing/fission', params)
}

// 流量主名单搜索
async function getFissionFlowList(params) {
    return await get('/api-file/manage/marketing/fission/user/search', params)
}

// 添加流量主
async function addFissionFlow(params) {
    return await post('/api-file/manage/marketing/fission', params, { prompt: true })
}

// 删除流量主
async function delFissionFlow(id) {
    return await del('/api-file/manage/marketing/fission', id, { prompt: true })
}

// 裂变概况
async function getFissionDataList(params) {
    return await get('/api-file/manage/marketing/fission/overview', params)
}

// 裂变关系
async function getFissionRelationList(params) {
    return await get('/api-file/manage/marketing/fission/relation', params)
}
export default {
    getUrlList,
    addUrl,
    editUrl,
    delUrl,
    getTransferPage,
    editTransferPage,
    delTransferPage,
    getTransferPageByPage,
    getHavePriceScenario,
    getFissionList,
    getFissionFlowList,
    addFissionFlow,
    delFissionFlow,
    getFissionRelationList,
    getFissionDataList
}