import { quillRedefine } from 'vue-quill-editor-upload'
import { config } from '@configUrl'
// 图片上传配置
const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'size': ['small', false, 'large', 'huge'] }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    ['image']
]

const quillOptions = quillRedefine({
    // 图片上传的设置
    uploadConfig: {
        action: config + '/api-file/files-anon',  // 必填参数 图片上传地址
        // 必选参数  res是一个函数，函数接收的response为上传成功时服务器返回的数据
        // 你必须把返回的数据中所包含的图片地址 return 回去
        res: (res) => {
            return res.url
        },
        header: (xhr) => {
            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem("access_token"));
            return xhr
        },
        methods: 'POST', // 可选参数 图片上传方式  默认为post
        name: 'file', // 可选参数 文件的参数名 默认为img
        size: 1024 * 5, // 可选参数   图片限制大小，单位为Kb, 1M = 1024Kb
        accept: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon', // 可选参数 可上传的图片格式
        start: () => {
        }, // 可选参数 接收一个函数 开始上传数据时会触发
        end: () => {
        }, // 可选参数 接收一个函数 上传数据完成（成功或者失败）时会触发
        success: () => {
        }, // 可选参数 接收一个函数 上传数据成功时会触发
        error: () => {
        } // 可选参数 接收一个函数 上传数据中断时会触发
    },
    // 以下所有设置都和vue-quill-editor本身所对应
    placeholder: '',  // 可选参数 富文本框内的提示语
    theme: '',  // 可选参数 富文本编辑器的风格
    toolOptions: toolbarOptions,  // 可选参数  选择工具栏的需要哪些功能  默认是全部
    handlers: {}  // 可选参数 重定义的事件，比如link等事件
})

export {
    quillOptions   //富文本上传配置
}