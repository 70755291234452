<!--  -->
<template>
  <div class="">
    <el-button size="mini" style="margin-left: 10px" @click="modifyPassword"
      >修改密码</el-button
    >
    <el-dialog
      :append-to-body="true"
      :visible.sync="passwordDialog"
      width="385px"
    >
      <div slot="title" style="text-align: center">
        <span style="background: #eceafa; padding: 5px">修改密码</span>
      </div>
      <div class="flex flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>旧密码
        </div>
        <el-input
          onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
          style="margin-left: 10px; width: 212px"
          size="mini"
          placeholder="请输入旧密码"
          v-model="passwordForm.oldPassword"
        ></el-input>
      </div>
      <div class="flex flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>新密码
        </div>
        <el-input
          onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g,'')"
          style="margin: 8px 0 0 10px; width: 212px"
          size="mini"
          placeholder="请输入新密码"
          v-model="passwordForm.newPassword"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordDialog = false">取 消</el-button>
        <el-button type="primary" @click="setPassword">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      passwordForm: {
        oldPassword: "",
        newPassword: "",
      },
      passwordDialog: false,
    };
  },
  methods: {
    modifyPassword() {
      this.passwordDialog = true;
      this.passwordForm = this.$options.data().passwordForm;
    },
    async setPassword() {
      const res = await this.getRequest.setPassword(this.passwordForm);
      let code = res.respCode === 0;
      this.$message[code ? "success" : "error"](res.respMsg);
      code && (this.passwordDialog = false);
    },
  },
};
</script>
    
<style lang='' scoped>
</style>