import { Message } from 'element-ui';
/**
 * 取值函数
 * @param {String} exp 表达式
 * @param {Object} data 需要取值的对象
 * @param {String} cut 表达式切割符
 */
export const getExpValue = (exp, data, cut = '-') => {
    const arr = exp.split(cut)
    const res = arr.reduce((pre, cur) => {
        return pre ? pre[cur] : pre
    }, data)
    return res
}

/**
 * 防抖函数
 * @param {Function} fn 回调函数
 * @param {Number} delay 时长
 */
export const debounce = (fn, delay) => {
    var timer;
    return function () {
        var args = arguments;
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            fn.apply(this, args); // this 指向vue
        }, delay);
    };

}

/**
 * 时间戳转时间格式
 * @param {Number} timestamp 时间戳
 */
export const timestampToDate = (timestamp, isAll = true) => {
    var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
    var D = date.getDate() + ' ';
    var h = date.getHours() + ':';
    var m = date.getMinutes();
    if (m == '0') {
        m = '00'
    }
    if (!isAll) {
        D = D.trim()
        return (Y + M + (D < 10 ? '0' + D : D)).split('-').join('')
    }
    return Y + M + D + h + m;
}

/**
 * 处理参数
 * @param {Object} params 参数对象
 */
export const dealParams = (obj) => {
    Object.keys(obj).forEach(item => {
        typeof obj[item] === 'object' && (obj[item] = JSON.stringify(obj[item]))
    })
    return obj
}

/**
 * 深拷贝函数
 * @param {Object} obj 传入对象
 */
export const deepCopy = (obj) => {
    // 根据obj的类型判断是新建一个数组还是对象
    let newObj = Array.isArray(obj) ? [] : {};
    // 判断传入的obj存在，且类型为对象
    if (obj && typeof obj === 'object') {
        for (let key in obj) {
            // 如果obj的子元素是对象，则进行递归操作
            if (obj[key] && typeof obj[key] === 'object') {
                newObj[key] = deepCopy(obj[key])
            } else {
                // // 如果obj的子元素不是对象，则直接赋值
                newObj[key] = obj[key]
            }
        }
    }
    return newObj // 返回新对象
}

export const objDeepCopy = () => {
    // 根据obj的类型判断是新建一个数组还是对象
    let newObj = Array.isArray(this) ? [] : {};
    // 判断传入的obj存在，且类型为对象
    if (this && typeof this === 'object') {
        for (let key in this) {
            // 如果obj的子元素是对象，则进行递归操作
            if (this[key] && typeof this[key] === 'object') {
                newObj[key] = deepCopy(this[key])
            } else {
                // // 如果obj的子元素不是对象，则直接赋值
                newObj[key] = this[key]
            }
        }
    }
    return newObj // 返回新对象
}


/**
 * 数组根据某个属性去重
 * @param {Object} obj 传入对象
 * @param {String} key 属性
 */
export const removeDup = (obj, key) => {
    var temp = {};
    return obj.reduce((prev, curv) => {
        if (!temp[curv[key]]) {
            temp[curv[key]] = true;
            prev.push(curv);
        }
        return prev
    }, []);
}

export const formRule = (form, rules) => {
    let obj = null
    let flag = rules.every(item1 => {
        obj = JSON.parse(JSON.stringify(item1))
        return !Object.keys(item1.rule).length || Object.keys(item1.rule).every(item2 => {
            if (!item1.rule[item2]) return true
            obj.rule = item2
            return ruleObj[item2].fun(form[item1.label])
        })
    })
    if (!flag) {
        Message.warning(`${obj.name}${ruleObj[obj.rule].msg}`)
    }
    return flag
}


const ruleObj = {
    required: {
        fun: (value) => {
            return Boolean(value || (typeof value === 'number' && !value) || (typeof value === 'string' && value.trim()))
        },
        msg: '不能为空哦！'
    },
    zero: {
        fun: (value) => {
            const num = Number(value)
            return Boolean((!Number.isNaN(num) && num > 0) || num === -1)
        },
        msg: '只能是数字且不能小于 0 哦！'
    },
    emptyArr: {
        fun: (value) => {
            return Boolean(value && value.length)
        },
        msg: '至少要选一个哦！'
    },
    noNegative: {
        fun: (value) => {
            const num = Number(value)
            return Boolean(!Number.isNaN(num) && num >= 0)
        },
        msg: '只能是数字且不能为负数哦！'
    }
}

// 获取枚举值
export const toEnum = (data = {}) => {
    return Object.keys(data).reduce((p, c) => (p[p[c] = data[c]] = c, p), {})
}

export const checkRepeatKey = (arr, key) => {
    let obj = new Map()
    for (let i = 0; i < arr.length; i++) {
        const name = arr[i][key]
        if (obj.has(name)) {
            Message.warning('素材命名重复！')
            return false
        } else {
            obj.set(name, 1)
        }
    }
    return true
}

export function formatSeconds(value) {
    var theTime = parseInt(value);// 秒
    var middle = 0;// 分
    var hour = 0;// 小时

    if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
            hour = parseInt(middle / 60);
            middle = parseInt(middle % 60);
        }
    }
    var result = "" + parseInt(theTime) + "秒";
    if (middle > 0) {
        result = "" + parseInt(middle) + "分" + result;
    }
    if (hour > 0) {
        result = "" + parseInt(hour) + "小时" + result;
    }
    return result;
}

export const myFill = (item, num) => {
    const arr = []
    for (let i = 0; i < num; i++) {
        arr.push(deepCopy(item))
    }
    return arr
}

