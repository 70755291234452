import {
    post,
    get
} from '../interface'

// 添加剧本角标
async function addCorner(obj) {
    const res = await post(`api-scenario/scenario/corner/mark`, obj, {
        prompt: true
    })
    return res
}
// 获取剧本角标信息
async function getCorner(id) {
    const
        res
            = await get(`/api-scenario/scenario/corner/mark/${id}`,{}, {
                noWarning: true
            })
    return res
}

export default {
    addCorner,
    getCorner
}